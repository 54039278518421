import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import davinesLogo from "../images/davines-logo.png"
import { colors } from "Utilities"
import { Button } from "../Elements/Button"
import { Card } from "../Elements"


const DavinesShop = ({ className }) => (
  <StaticQuery
    query={graphql`
      query DavinesShopQuery {
        file(relativePath: {eq: "davines.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 1960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.file.childImageSharp.fluid
      
      const textStyles = {
        margin: 0,
      }
      
      const linkStyles = {
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 0,
        alignItems: "center",
        color: "black",
        fontSize: "0.8rem",
        textDecoration: "none",
      };

      return (

        
          <DavinesShopOnlineWrapper>
          <BackgroundImage
          className={className}
          fluid={imageData}
          alt="Davines Products with link to shop online"  
        >
            <TextDiv>
              <TextWrapper>
                <p style={textStyles}>BUY</p>
                <h2 style={textStyles}>DAVINES</h2>
                <p style={textStyles}>HAIRCARE</p>
              </TextWrapper>
              <ShopButtonWrapper> 
                <Button>
                  <a href="https://my-salon.shop/?salonname=Storm%20Hair&salonlocation=Street"
                  target="_blank" 
                  rel="noopener noreferrer" 
                  style={linkStyles}>Shop Now</a>
                </Button>
              </ShopButtonWrapper> 
              <LogoWrapper>
                <LogoImage src={davinesLogo} alt="Davines Logo" />
              </LogoWrapper>
            </TextDiv>
            
            
            

            </BackgroundImage>
          </DavinesShopOnlineWrapper> 
        
      )
    }}
  />
)


const DavinesShopOnlineWrapper = styled(Card)`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  justify-self: center;
  padding: 0;
  background: ${colors.lightGrey};
`;

const StyledDavinesShop = styled(DavinesShop)`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
`

const TextDiv = styled.div`
  grid-column: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ShopButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -0.5rem;
`

const LogoWrapper = styled.div`
  margin-bottom: -2.0rem;
  margin-left: auto;
  margin-right: auto; 
  width: 150px;
  @media (max-width: 768px) {
    width: 100px;
  }
`


const LogoImage = styled.img`
  width: 100%;
`

export default StyledDavinesShop