import React, { Component } from 'react'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import StyledDavinesShop from "../components/davines-shop"
import styled from "styled-components"

gsap.registerPlugin(ScrollTrigger);

class DavinesAnimation extends Component {

  componentDidMount(){
    gsap.from('.davines', {
      scrollTrigger: {
        trigger: ".davines",
        start: "top 80%",
        toggleActions:"play none none none"
      },
      y: 200,
      opacity: 0,
      ease: "ease",
      duration: 1
    });
  }  
  
  render() {
    return(
      <DavinesWrapper ref={div => this.myElement = div} className="davines">
        <StyledDavinesShop/>
      </DavinesWrapper>
    )
  }
}

export default DavinesAnimation

const DavinesWrapper = styled.div`
  grid-area: davinesShop;
  width: 100%;
  height: 200px;
  margin-bottom: 5%;
`;