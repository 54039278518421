import React from "react"
import styled from "styled-components"
import { colors } from "Utilities"
import crueltyFreeLogo from "../images/cruelty-free.png"
import { Card } from "../Elements";

const crueltyFreeStyle = {
  width: "150px",
};

const CrueltyLogo = () => (
  <CrueltyContainer>
    <CrueltyFreeWrapper>
      <a href="https://www.peta.org/living/personal-care-fashion/beauty-without-bunnies/" 
        target="_blank" 
        rel="noopener noreferrer">
        <img style={crueltyFreeStyle} src={crueltyFreeLogo} alt="P.E.T.A Cruelty Free Logo" />
      </a>
      
    </CrueltyFreeWrapper>
    <CrueltyLogoText>
      <CrueltyText>“Being the best for the world, creators of good life for all through beauty, ethics and sustainability.” <br/>Davines      </CrueltyText>
    </CrueltyLogoText>
  </CrueltyContainer> 
  )

  export default CrueltyLogo

  const CrueltyContainer = styled(Card)`
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    padding: 0;
    background: ${colors.black};
    opacity: 0.8;
    @media (max-width: 769px) {
      width: 100%;
    }
  `;

  const CrueltyFreeWrapper = styled.div`
  width: 150px;
  justify-self: center;
  @media (max-width: 769px) {
    width: 120px;
    
  }
`
const CrueltyLogoText = styled.div`
  width: 200px;
  @media (max-width: 769px) {
    width: 200px;
  }
`;

const CrueltyText = styled.p`
  color: ${colors.white};
  text-shadow: "10px 50px #222";
  @media (max-width: 769px) {
    font-size: 0.9rem;
  }
`;
