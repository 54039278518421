import React from "react"
import SEO from "../components/seo"
import styled from "styled-components"
import Layout from "../components/NewLayout"
import StyledFrontPageBanner from "../components/landingPageBg"
import DavinesAnimation from "../animations/DavinesAnimation"
import CrueltyLogoAnimation from "../animations/CrueltyLogoAnimation"
import { colors } from "Utilities"


const linkStyles = {
  margin: 0,
  alignItems: "center",
  justifyContents: "center",
  color: "black",
  fontSize: "1rem",
  textDecoration: "none",
};

const phoneStyle = {
  fontFamily: "Fira Sans",
}

const IndexPage = () => (
  
  <Layout>
    <SEO title="Home" />
    <StyledFrontPageBanner>
    <Container>
    
    <AppointmentWrapper>
      <AppointmentText>For an Appointment Call:</AppointmentText>
      <AppointmentText style={phoneStyle}><a href="tel:01458448080">01458 448080</a></AppointmentText>
      
    </AppointmentWrapper>
    
    <CrueltyLogoAnimation/>
    
    
    <DavinesAnimation/>
    
    
    </Container>
  </StyledFrontPageBanner> 
  </Layout>
  
)

export default IndexPage

const Container = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
  "appointment empty empty"
  "crueltyFree davinesShop davinesShop";
  width: 100%;
  height: 100%;
  padding: 0 4rem;
  @media (max-width: 769px) {
    grid-template-areas:
    "appointment empty"
    "crueltyFree crueltyFree" 
    "davinesShop davinesShop";
    padding: 0 3rem;
  }
  @media (max-width: 469px) {
    padding: 0 1rem;
  }
`;

const AppointmentWrapper = styled.div`
  grid-area: appointment;
  margin-top: 8%;
  margin-bottom: 1.1rem;
  justify-self: end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 500px;
  @media (max-width: 769px) {
    width: 100%;
    justify-self: center;
  }
`;

const AppointmentText = styled.h2`
  margin: 0;
  color: ${colors.white};
  font-size: 2rem;
  font-weight: lighter;
  text-shadow: 2px 2px ${colors.black};
  a {
    color: ${colors.white};
    text-decoration: underline;
  }
  @media (max-width: 769px) {
    font-size: 1.5rem;
  }
`;


    

      
    
    
    
    
     


  


    
  
  



