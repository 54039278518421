import React, { Component } from 'react'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import CrueltyLogo from "../components/crueltyLogo"
import styled from "styled-components"

gsap.registerPlugin(ScrollTrigger);

class CrueltyLogoAnimation extends Component {

  componentDidMount(){
    gsap.from('.crueltyLogo', {
      scrollTrigger: {
        trigger: ".crueltyLogo",
        start: "top 80%",
        toggleActions:"play none none none"
      },
      y: 200,
      opacity: 0,
      ease: "ease",
      duration: 1
    });
  }  
  render() {
    return(
      <CrueltyWrapper ref={div => this.myElement = div} className="crueltyLogo">
        <CrueltyLogo/>
      </CrueltyWrapper>
    )
  }
}

export default CrueltyLogoAnimation

const CrueltyWrapper = styled.div`
  grid-area: crueltyFree;
  width: 100%;
  height: 200px;
  
`;